<template>
  <div class="_bg-default px-2">
    <section class="indigo pa-2 rounded d-flex mb-3">
      <img src="../../assets/img/logs.svg" height="100px" />
      <div>
        <h1 class="white--text ml-3">Log Aktivitas</h1>
      </div>
    </section>
    <div class="temp-tl" v-if="feeds">
      <div class="post-status-temp mt-4" v-for="(log, i) in feeds" :key="i">
        <section class="d-flex align-center">
          <!-- <v-avatar size="40" color="indigo">
                <span class="white--text font-weight-bold">{{
                  log.nama_grup[0].toUpperCase()
                }}</span>
              </v-avatar> -->
          <!-- <h3 class="mx-3">{{ log.nama_grup }}</h3> -->
        </section>
        <section class="my-2 d-flex">
          <div style="width: 50px"></div>
          <v-card class="pa-3" style="width: 90%">
            <span v-html="log.keterangan"></span>
          </v-card>
        </section>
        <footer class="d-flex">
          <div style="width: 50px"></div>
          <div>
            <v-icon color="grey" small>mdi-clock-outline</v-icon>
            <small class="grey--text ml-2"
              >{{ moment(log.created_at).format("HH:mm") }} |
              {{ moment(log.created_at).format("DD MMMM YYYY") }}</small
            >
          </div>
        </footer>
      </div>
      <div
        v-if="allActivity.data"
        v-observe-visibility="handleScrolledToBottom"
      >
      </div>
    </div>
    <div class="d-flex justify-center" v-if="!allActivity.data">
      <v-progress-circular
        indeterminate
        size="30"
        color="indigo"
        class="mr-2 ml-2"
      ></v-progress-circular>
      memuat
    </div>
  </div>
</template>

<script>
import { ACTIVITY } from "../../graphql/graphql";
import moment from "moment";
import { mapState } from "vuex";
export default {
  apollo: {
    allActivity: {
      query: ACTIVITY,
      variables() {
        return { page: this.page, first: this.limit };
      }
    }
  },
  computed: {
    ...mapState(["feeds", "lastLog"])
  },
  mounted() {
    this.$store.dispatch("theFeeds", {
      per_page: this.limit,
      page: this.page
    }); //get feeds
  },
  name: "log",
  data() {
    return {
      allActivity: null,
      moment: moment,
      page: 1,
      limit: 10
    };
  },
  methods: {
    handleScrolledToBottom(isVisible) {
      if (isVisible) {
        if (this.page >= this.lastLog) {
          this.page++;
          console.log(this.allActivity.data);
          this.feeds.push(...this.allActivity.data);
        }
      }
    }
  }
};
</script>

<style scoped>
.post-status-temp {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.temp-tl {
  padding: 0 20px 0 20px;
}
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
